import { PayPalButtons } from '@paypal/react-paypal-js';
import React from 'react';
import { useParams } from 'react-router';

const currency = 'USD';
const style = { layout: 'vertical' };

const chargeOnError = (err) => {
	return (
		window.ReactNativeWebView &&
		window.ReactNativeWebView.postMessage(
			JSON.stringify({ status: 'Error' })
		)
	);
};
const chargeOnCancel = (err) => {
	return (
		window.ReactNativeWebView &&
		window.ReactNativeWebView.postMessage(
			JSON.stringify({ status: 'cancel' })
		)
	);
};
const chargeOncatchErr = (err) => {
	return (
		window.ReactNativeWebView &&
		window.ReactNativeWebView.postMessage(
			JSON.stringify({ status: 'cancel' })
		)
	);
};
const chargeOnApprove = (data, detail) => {
	// call the backend api to store transaction details
	console.log("Data", data)
	console.log("detail", detail)
	return detail.order.capture().then((details) => {
		console.log(details)
		window.ReactNativeWebView &&
		window.ReactNativeWebView.postMessage(
			JSON.stringify({ data: details, status: 'sucess' })
		)
	});
};

const PayPalChk = (props) => {
	let { amt } = useParams();
	return (
		<PayPalButtons
			style={style}
			forceReRender={[amt, currency, style]}
			createOrder={(data, actions) => {
				// console.log('actions', actions)
				return actions.order.create({
					purchase_units: [
						{
							amount: {
								value: amt	,
							},
						},
					],
				});
			}}
			onApprove={(data,action) => chargeOnApprove(data,action)}
			onError={(err) => chargeOnError(err)}
			catchError={(err) => chargeOncatchErr(err)}
			onCancel={(err) => chargeOnCancel(err)}
		/>
	);
};
export default PayPalChk;