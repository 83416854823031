import { PayPalButton } from 'react-paypal-button-v2';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';

const paypalOnError = (err) => {
	return (
		window.ReactNativeWebView &&
		window.ReactNativeWebView.postMessage(
			JSON.stringify({ status: 'Error' })
		)
	);
};
const paypalOnCancel = (err) => {
	return (
		window.ReactNativeWebView &&
		window.ReactNativeWebView.postMessage(
			JSON.stringify({ status: 'cancel' })
		)
	);
};
const paypalOncatchErr = (err) => {
	return (
		window.ReactNativeWebView &&
		window.ReactNativeWebView.postMessage(
			JSON.stringify({ status: 'cancel' })
		)
	);
};
const paypalOnApprove = (data, detail) => {
	// call the backend api to store transaction details

	return (
		window.ReactNativeWebView &&
		window.ReactNativeWebView.postMessage(
			JSON.stringify({ data: data, status: 'sucess' })
		)
	);
};
const PayPalBtn = (props) => {
	let { planId } = useParams();
	const [paypalLoaded, setPaypalLoaded] = useState(false);

	useEffect(() => {setPaypalLoaded(true)}, []);

	const {
		currency,
	} = props;

	useEffect(() => {setPaypalLoaded(true)}, []);

	return (
		<>
		<div style={{margin:'20px'}}>
			{paypalLoaded ? (
				<PayPalButton				
					currency={currency}
					createSubscription={(data, details) => {
						//console.log('data create', data);
						return details.subscription.create({
							/* Creates the subscription */
							plan_id: planId,
						});
					}}
					onApprove={(err) => paypalOnApprove(err)}
					onError={(err) => paypalOnError(err)}
					catchError={(err) => paypalOncatchErr(err)}
					onCancel={(err) => paypalOnCancel(err)}
					options={{
						clientId: process.env.REACT_APP_ClientId,
						vault: true,
					}}
					style={{
						shape: 'rect',
						color: 'blue',
						layout: 'vertical',
						label: 'subscribe',
						padding: '10px',
					}}
					onButtonReady={() => {
						setPaypalLoaded(true);
					}}
				/>
			) : null}
			</div>
		</>
	);
};
export default PayPalBtn;
