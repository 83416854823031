import React, { Component } from 'react';
import { Routes, Route, } from 'react-router-dom';
import Home from './components/home';
import PayPalBtn from './components/PayPalBtn';
import PayPalChk from './components/PayPalChk';
import './App.css';
import {
  PayPalScriptProvider
} from "@paypal/react-paypal-js";
import Layout from './components/Layout';

class App extends Component {
  render() {
    return (
      <div className="App">
        <PayPalScriptProvider
          options={{
            "client-id": process.env.REACT_APP_ClientId,
            components: "buttons",
            currency: "USD",
            vault: true
          }}
        >
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route exact index element={<Home />}></Route>
              <Route exact path='/PayPalBtn/:planId' element={<PayPalBtn />}></Route>
              <Route exact path='/PayPalChk/:amt' element={<PayPalChk />}></Route>
            </Route>
          </Routes>
        </PayPalScriptProvider>
      </div>
    );
  }
}

export default App;
