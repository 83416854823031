import React from "react";
import { Outlet } from "react-router-dom";

const Layout = () => {
    return (
        <>
            {/* <br></br>
            <li>
                <Link to="/">Home</Link>
            </li><br></br>
            <li>
                <Link to="/PayPalBtn/:uid">Paypal button</Link>
            </li><br></br>
            <li>
                <Link to="/PayPalChk/:amt/:uid">Paypal charge</Link>
            </li> <br></br> */}
            <Outlet />
        </>
    );
};

export default Layout;
